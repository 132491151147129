'use client';
import HomeViewPaper from '@/src/components/HomeViewPaper';
import NatomaDescope from '@/src/components/NatomaDescope';

export default function HomeLoginPage() {
  return (
    <HomeViewPaper>
      <NatomaDescope flowId="home-sign-up-or-in" />
    </HomeViewPaper>
  );
}
